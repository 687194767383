import AOS from 'aos';

document.addEventListener("turbolinks:load", () => {
  // Initialize for AOS
  AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

  });

  // When the user scrolls the page, sticky header
  window.onscroll = function() {
    stickyGoToTop()};

  // Get the header
  var goToTop = document.getElementById("go_to_top");

  // Show go to top icon
  function stickyGoToTop() {
    if (window.pageYOffset > 500) {
      goToTop.classList.add("sticky");
    } else {
      goToTop.classList.remove("sticky");
    }
  }

  // Navigate cho danh muc pho bien

  $( ".section-danhmuc .navigation .right-nav" ).click(function() {
    $('#danhmuc_items').animate({
      scrollLeft: $('#danhmuc_items').scrollLeft() + 600
    }, 500)
  });
  $( ".section-danhmuc .navigation .left-nav" ).click(function() {
    $('#danhmuc_items').animate({
      scrollLeft: $('#danhmuc_items').scrollLeft() - 600
    }, 500)
  });

  $(".slider").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
});
